<template>
  <div>
    <div class="container-upload">
      <p>ลากวางหรือคลิกเพื่ออัพโหลดรูปภาพ</p>
      <input type="file" ref="refInputUploadImages" accept="image/jpeg, image/png" @input="uploadImages($event)" />
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="danger"
      block
      class="my-1"
      size="sm"
      @click="previewRecommendImageDimension"
    >
      คลิกเพื่อดูขนาดรูปที่แนะนำ
    </b-button>
    <br />
    <div v-for="(image, index) in wallpaperDatas.allImage" :key="image.localId">
      <b-card class="card-image-item" :class="validateDimensionImage(image)">
        <b-button v-if="!image.id" variant="danger" class="btn-delete" size="sm" @click="removeImage(index, image)">
          ลบ
        </b-button>
        <b-row>
          <b-col sm="12" md="4">
            <div class="container-image-preview ct-cursor-zoom">
              <img :src="image.file.previewUrl" alt="img-preview" class="image-preview" @click="gZoomImage" />
            </div>
          </b-col>
          <b-col class="mt-sm-2 mt-md-0">
            <b-row>
              <b-col sm="12" md="4">
                <h5>
                  รายละเอียดรูปภาพ
                </h5>
                <ul>
                  <li>
                    {{ image.file.naturalWidth }}
                    <small class="text-muted">
                      width
                    </small>
                  </li>
                  <li>
                    {{ image.file.naturalHeight }}
                    <small class="text-muted">
                      height
                    </small>
                  </li>
                </ul>
                <h5 class="mb-0">
                  รายละเอียดการใช้ AI
                </h5>
                <div v-if="image.ai_generate_data">
                  <small>*เกณฑ์ที่กำหนดต้องใช้ AI ในการสร้างน้อยกว่า {{ image.ai_generate_data.threshold }}%</small>
                  <ul class="mt-50">
                    <li>AI สร้าง {{ image.ai_generate_data ? `${image.ai_generate_data.score}%` : '-' }}</li>
                    <li :class="[image.ai_generate_data.ai_generated ? 'text-danger' : 'text-success']">
                      {{ image.ai_generate_data.ai_generated ? 'ไม่อยู่ในเกณฑ์ที่กำหนด' : 'อยู่ในเกณฑ์ที่กำหนด' }}
                    </li>
                  </ul>
                </div>

                <div v-else class="mt-50 mb-1">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    block
                    @click="clickCheckImageGenerateAIDetails(image)"
                  >
                    แสดงรายละเอียด
                  </b-button>
                </div>
              </b-col>
              <b-col>
                <h5>
                  ตั้งค่ารายละเอียด
                </h5>
                <b-form>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                        <label for="a-code">
                          ชื่อรูปนี้
                        </label>
                        <small v-if="translationTabKey === 'th'" class="text-danger"> *จำเป็นต้องระบุ</small>
                        <small v-else> (ถ้าไม่ระบุ จะแปลให้โดยอัตโนมัติ)</small>
                        <!-- <feather-icon
                          v-b-tooltip.hover
                          icon="HelpCircleIcon"
                          size="16"
                          title="test tooltip"
                          class="ml-50"
                        /> -->
                        <b-form-input
                          id="d-code"
                          v-model="image.options.title_translations[translationTabKey]"
                          type="text"
                          placeholder="ระบุชื่อรูปวอลเปเปอร์นี้"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group>
                        <label for="b-code">รายละเอียดรูปนี้</label>
                        <small v-if="translationTabKey !== 'th'"> (ถ้าไม่ระบุ จะแปลให้โดยอัตโนมัติ)</small>
                        <b-form-textarea
                          id="a-code"
                          v-model="image.options.details_translations[translationTabKey]"
                          type="text"
                          placeholder="ระบุรายละเอียดวอลเปเปอร์นี้"
                          rows="4"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group>
                        <label for="c-code">ราคา</label>
                        <!-- <small class="text-danger"> {{ calcPriceAfterTax(image) }}</small> -->
                        <b-form-input
                          @keypress="isNumber($event)"
                          id="b-code"
                          v-model="image.options.price"
                          placeholder="ระบุราคารูป"
                        />
                        <small class="text-danger" v-if="wallpaperDatas.mainImage.revenue_sharing > 0">
                          ราคาหลักจากหักค่าบริการ
                          {{ wallpaperDatas.mainImage.revenue_sharing }}%
                          <span v-if="wallpaperDatas.mainImage.affiliate.join_status">
                            + ค่าคอมมิชชั่น Affiliate {{ wallpaperDatas.mainImage.affiliate.commission_rate }}%
                          </span>
                          = จะได้รับ {{ calcPriceAfterTaxDisplay(image) }} บาท
                        </small>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="สีลายน้ำ" label-for="d-code">
                        <b-form-radio-group
                          v-model="image.options.watermarkColor"
                          :options="optionsWatermarkColor"
                          name="c-code"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="ผูกดวงประจำวัน (ไม่จำเป็น)" label-for="e-code">
                        <v-select v-model="image.options.dayColor" label="title" :options="optionsDay" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="สีตัวอักษร" label-for="f-code">
                        <b-form-input id="c-code" type="color" v-model="image.options.fontColor" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    ToastificationContent,
    vSelect,
  },
  props: {
    translationTabKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('AuthStore', ['_userInfo']),
    ...mapState('wallpapers', ['wallpaperDatas']),
    optionsDay() {
      return ['จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์', 'อาทิตย์']
    },
    optionsWatermarkColor() {
      return [
        { text: 'ดำ', value: 'black' },
        { text: 'ขาว', value: 'white' },
      ]
    },
  },
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    checkDimensionImage(imgUrl) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = e => {
          // const { naturalWidth, naturalHeight } = e.path[0] // e.path บางบราวเซอร์ไม่มีทำให้แตก
          const { naturalWidth, naturalHeight } = img
          resolve({ naturalHeight, naturalWidth })
        }
        img.onerror = reject

        img.src = imgUrl
      })
    },
    async uploadImages(event) {
      const filesObj = event.target.files

      /* start logic check image generateAI */
      this.gOpenPageLoading()
      const aiData = await this.gCheckImageGenerateAI(filesObj[0])
      this.gDisplayToast(
        'ตอนนี้ระบบตรวจเช็ค AI ไม่สามารถทำงานได้',
        'ข้อมูลเกี่ยวกับ AI ที่ได้อาจจะไม่ตรงตามความเป็นจริง',
        'danger',
      )
      this.gClosePageLoading()
      if (aiData && typeof aiData === 'object') {
        const { ai_generated, score, threshold } = aiData
        // eslint-disable-next-line camelcase
        if (ai_generated) {
          this.$refs.refInputUploadImages.value = null
          this.gAlertV1(
            'ไม่สามารถอัปโหลดรูปนี้ได้',
            `⚠️ ตรวจพบรูปมีการใช้ AI ในการสร้าง ${score}% ซึ่งมากกว่าที่ทาง ongphra wallpaper กำหนดไว้ โดยรูปที่สามารถอัปโหลดได้ต้องใช้ AI สร้างน้อยกว่า ${threshold}%`,
          )
          return
        }
      } else {
        this.$refs.refInputUploadImages.value = null
        this.gDisplayToast('พบข้อผิดพลาดเกี่ยวกับการเช็ค AI', 'โปรดลองใหม่อีกครั้ง', 'danger')
        return
      }
      /* end logic check image generateAI */

      Object.keys(filesObj).map(async val => {
        const file = filesObj[val]
        // console.log('file', file)
        const fileSize = Number(file.size / (1024 * 1024)).toFixed(2)
        // console.log('fileSize', fileSize)
        if (fileSize > 10) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'ขนาดรูปผิดพลาด',
              icon: 'BellIcon',
              text: 'ขนาดรูปที่ต้องการต้องไม่เกิน 10 mb',
              variant: 'danger',
            },
          })
          return
        }
        const previewUrl = URL.createObjectURL(file)
        file.previewUrl = previewUrl

        try {
          const dimension = await this.checkDimensionImage(previewUrl)

          file.naturalHeight = dimension.naturalHeight
          file.naturalWidth = dimension.naturalWidth
          const localId = uuidv4()
          file.localId = localId
          const newFile = {
            localId,
            file,
            options: {
              price: 0,
              fontColor: '#000',
              dayColor: null,
              title: '',
              detail: '',
              watermarkColor: 'black',
              title_translations: {
                th: '',
              },
              details_translations: {
                th: '',
              },
            },
            ai_generate_data: aiData,
          }
          this.wallpaperDatas.allImage = [...this.wallpaperDatas.allImage, newFile]
        } catch (e) {
          //
        }
      })
      this.wallpaperDatas.mainImage.file = null
      this.$refs.refInputUploadImages.value = null
    },
    async removeImage(index, imageInfo) {
      if (imageInfo?.id) {
        this.gDisplayToast('ไม่สามารถลบรูปได้', 'เนื่องจากรูปนี้เคยรีวิวไปแล้ว', 'danger')
        return
        // const resp = await this.api.deleteV2(`/api/admin/admin-topic-image-lists/${imageInfo.id}`, this)
        // if (!resp) return
      }
      this.wallpaperDatas.mainImage.file = null
      this.wallpaperDatas.allImage.splice(index, 1)
    },
    validateDimensionImage(image) {
      const allImage = this.wallpaperDatas.allImage
      if (allImage.length <= 1) return ''
      const { naturalWidth, naturalHeight } = image.file
      const firstImage = allImage[0]
      if (naturalHeight !== firstImage.file.naturalHeight && naturalWidth !== firstImage.file.naturalWidth) {
        return 'error'
      }

      return ''
    },
    calcPriceAfterTaxDisplay(item) {
      const n = Number(item.options.price || 0)
      // console.log('_userInfo', this._userInfo)
      const { join_status, commission_rate } = this.wallpaperDatas.mainImage.affiliate

      // eslint-disable-next-line camelcase
      if (join_status && commission_rate > 0 && commission_rate <= 10) {
        // eslint-disable-next-line camelcase, no-underscore-dangle
        return this.gFormatNumberToCurrency(
          // eslint-disable-next-line camelcase
          n - n * (this.wallpaperDatas.mainImage.revenue_sharing / 100) - n * (commission_rate / 100),
        )
      }

      // eslint-disable-next-line no-underscore-dangle
      return this.gFormatNumberToCurrency(n - n * (this.wallpaperDatas.mainImage.revenue_sharing / 100))
    },
    previewRecommendImageDimension() {
      this.$viewerApi({
        // eslint-disable-next-line import/no-dynamic-require, global-require
        images: [
          require('@/assets/images/ongphra/guideline_1.jpg'),
          require('@/assets/images/ongphra/guideline_2.jpg'),
        ],
        options: {
          navbar: true,
          title: false,
        },
      })
    },
    async clickCheckImageGenerateAIDetails(values) {
      // console.log('clickCheckImageGenerateAIDetails', values)
      try {
        this.gOpenPageLoading()
        // 'https://lzd-img-global.slatic.net/g/ot/homepage/2f1fe0849742ab075583b45a6f10280a.jpeg_720x720q80.jpg_.webp'
        const urlForCreateFile = values.file.previewUrl
        const resp = await fetch(urlForCreateFile, { method: 'GET' })
        const blob = await resp.blob()
        const file = new File([blob], `${values.id}.jpg`, { type: blob.type })
        // console.log('file', file)

        const aiData = await this.gCheckImageGenerateAI(file)
        this.gDisplayToast(
          'ตอนนี้ระบบตรวจเช็ค AI ไม่สามารถทำงานได้',
          'ข้อมูลเกี่ยวกับ AI ที่ได้อาจจะไม่ตรงตามความเป็นจริง',
          'danger',
        )
        // console.log('clickCheckImageGenerateAIDetails', aiData)

        if (aiData && typeof aiData === 'object') {
          this.wallpaperDatas.allImage = [...this.wallpaperDatas.allImage].map(v => {
            if (v.id === values.id) {
              return { ...v, ai_generate_data: aiData }
            }

            return v
          })
        } else {
          this.gDisplayToast('พบข้อผิดพลาดเกี่ยวกับการเช็ค AI', 'โปรดลองใหม่อีกครั้ง', 'danger')
        }
      } catch (error) {
        this.gDisplayToast('พบข้อผิดพลาดเกี่ยวกับการเช็ค AI', 'โปรดลองใหม่อีกครั้ง', 'danger')
      } finally {
        this.gClosePageLoading()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  height: 100%;
  object-fit: scale-down;
}

.container-upload {
  width: 100%;
  height: 100px;
  background-color: whitesmoke;
  border-radius: 0.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgb(187, 185, 185);
  cursor: pointer;
  p {
    margin: 0;
  }
  input {
    width: 100%;
    height: 100%;
    border: 1px solid red;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.card-image-item {
  position: relative;
  &.error {
    border: 1px solid red;
  }
  .btn-delete {
    position: absolute;
    top: -10px;
    right: -5px;
    padding: 0.5rem;
    z-index: 2;
  }
  .container-image-preview {
    width: 100%;
    height: 200px;
    overflow: hidden;
    background-color: #47526f;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
